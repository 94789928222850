import { fonts } from 'styles/fonts';

export { default as wrapRootElement } from './wrap-root-element';

export function onInitialClientRender() {
  // Load fonts.
  if (typeof document !== 'undefined' && 'fonts' in document) {
    fonts.forEach(async ({ family, src, descriptors }) => {
      const source = `url(${src.url}) format('${src.format}')`;
      const fontFace = new FontFace(family, source, descriptors);
      const font = await fontFace.load();
      document.fonts.add(font);
    });
  }

  // A 'scrollend' event polyfill.
  // Used by the Carousel component.
  // @link https://github.com/argyleink/scrollyfills
  // eslint-disable-next-line no-unused-vars
  import('scrollyfills');
}
