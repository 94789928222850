import InterBold from '../../static/fonts/Inter-Bold.woff2';
import InterBoldSubset from '../../static/fonts/Inter-Bold-subset.woff2';
import InterRegular from '../../static/fonts/Inter-Regular.woff2';
import InterRegularSubset from '../../static/fonts/Inter-Regular-subset.woff2';
import SatoshiBold from '../../static/fonts/Satoshi-Bold.woff2';
import SatoshiBoldSubset from '../../static/fonts/Satoshi-Bold-subset.woff2';
import SatoshiMedium from '../../static/fonts/Satoshi-Medium.woff2';
import SatoshiMediumSubset from '../../static/fonts/Satoshi-Medium-subset.woff2';

export const fonts = [
  {
    family: 'Satoshi',
    src: { url: SatoshiBold, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'bold' },
  },
  {
    family: 'Satoshi',
    src: { url: SatoshiMedium, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'normal' },
  },
  {
    family: 'Inter',
    src: { url: InterRegular, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'normal' },
  },
  {
    family: 'Inter',
    src: { url: InterBold, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'bold' },
  },
];

export const fontSubsets = [
  {
    family: 'Satoshi',
    src: { url: SatoshiBoldSubset, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'bold' },
  },
  {
    family: 'Satoshi',
    src: { url: SatoshiMediumSubset, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'normal' },
  },
  {
    family: 'Inter',
    src: { url: InterRegularSubset, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'normal' },
  },
  {
    family: 'Inter',
    src: { url: InterBoldSubset, format: 'woff2' },
    descriptors: { style: 'normal', weight: 'bold' },
  },
];
